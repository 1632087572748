import React from 'react';
import {Helmet} from "react-helmet";
import Header from "../Components/Header";
import Player from "../Components/AudioStop/Player";
import Div100vh from 'react-div-100vh'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
var loadNode  = require('../utils/api.js').loadNode;

export default class AudioDetailsView extends React.Component {
  constructor() {
    super();
    this.state = { audio: null, tourId:null, displayText:false};

  }


  componentDidMount() {
    loadNode(this.props.match.params.id, this.updateData.bind(this))
  }


  updateData(responseData) {
    console.log(responseData)
    if(responseData.tours.length > 0) {
    	loadNode(responseData.tours[0]["nid"][0]["value"], this.updateTourId.bind(this))
    }
    this.setState({audio: responseData});
  }

  updateTourId(responseData) {
  	if(responseData.field_tour_items.length > 1){
  		this.setState({tourId: responseData.nid[0]["value"]});
  	}
  }


  switchView = () => {
	   this.setState({displayText:!this.state.displayText});
  }


  render() {
    const audio = this.state.audio
    
  
    let backgroundImage = "/images/placeholder-image.jpg";
    if(audio != null && audio.field_item_thumbnail.length>0){
       backgroundImage = audio.field_item_thumbnail[0]["value"].replace(/ /g, '%20')

    }
   


    return (
    	<DeviceOrientation lockOrientation={'portrait'}>
        <Orientation orientation='portrait' alwaysRender={false}>
      <Div100vh  dontresize="true" className="audiodetails" >
        {(audio != null)  &&
          (<Helmet>
              <title>IAMLA | Tour {audio.title[0]["value"]}</title>
              <meta name="description" content={"IAMLA | Tour "+audio.title[0]["value"]}/>
          </Helmet>)
          }

        <Header backURL={(this.state.tourId === null) ? "/" : "/tour/"+this.state.tourId}/>
        
           {(audio != null)  &&
           (
            <div className="container">

            	<div className="audiodetails-toppart">
	              <h1 className="header-title">{audio.title[0]["value"]}</h1>

	              <div className="audio-toggler">
	              	<div onClick={this.switchView} className={` ${!this.state.displayText ? "active" : ""}`}>Audio</div>
	              	<div onClick={this.switchView} className={` ${this.state.displayText ? "active" : ""}`}>Text</div>
	              </div>
	            </div>
              <div className={` ${this.state.displayText ? "audiodetails-image-container margins" : " audiodetails-image-container "}`}>
              	<div  style={{ backgroundImage: `url(${backgroundImage})` }} className={` ${!this.state.displayText ? "image-details" : " image-details hidden"}`}></div>



              	<div className={` ${this.state.displayText ? "text-details" : " text-details hidden"}`}>
				  <div className="text-content-details"   dangerouslySetInnerHTML={{ __html: audio.field_item_description[0]["value"] }}></div>
				</div>

              	{/* <div className={` ${this.state.displayText ? "fade-away" : " fade-away hidden"}`}></div> */}
              </div>


              <div className={` ${this.state.displayText ? "audio-controls hidden" : " audio-controls "}`}>
              	<p className="audiodetails-credits darkGreyCopy">{audio.field_item_plain_description.length>0 ? audio.field_item_plain_description[0]["value"] : " "}</p>


              	{(audio.field_audio.length !== 0)  &&
				(
				<Player audio={audio.field_audio["file_url"]} />
				)}
              </div>
              
              
              
            </div>
            )}
      
      </Div100vh>
      </Orientation>

        <Orientation orientation='landscape'  alwaysRender={false}>
          <Div100vh  dontresize="true" className="landscapeDiv">
            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
          </Div100vh>
        </Orientation>


      </DeviceOrientation>
    );
  }

}