import React from 'react';

export default class Footer extends React.Component {

  render() {
  	
  

    return (
      <div className="footer">
          <p>©2021 The Italian American Museum Of Los Angeles. All Rights Reserved.</p>
      </div>
    );
  }
}