import React from 'react';
export default class TourItem extends React.Component {



  render() {
    const tour = this.props.tour

    let backgroundImage = "/images/placeholder-image.jpg";
    if(tour.field_tour_thumbnail.length>0){
       backgroundImage = tour.field_tour_thumbnail[0]["value"].replace(/ /g, '%20')

    }

   

    let title =tour.title[0]["value"]
    
    return (

      <div className="relative">
      	<a href={tour.field_tour_items.length === 1 ?  "/audio/"+tour.field_tour_items[0]["target_id"] : "/tour/"+tour.nid[0]["value"]}>
          <div className="image-picks" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
	        <h2>{title}</h2> 
        </a>

        
      </div>
    );

    
  }

}