
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import TourItem from "./Components/TourItem"

import Div100vh from 'react-div-100vh'
import store from 'store';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'

var loadTours  = require('./utils/api.js').loadTours;

class Home extends Component {

  constructor() {
    super();
    
    loadTours(this.updateData.bind(this))


    const hasLoaded = store.get("hasLoaded");


    this.state = { data: [], hasLoaded:hasLoaded};
  }


  updateData(responseData) {
    
    //console.log(responseData)
    this.setState({data: responseData});
    store.set('hasLoaded', true);
  }


  

  render() {
    const toursView = this.state.data.map((tour, index) => {
        return (<TourItem tour={tour} index={index+1} key={tour.title[0]["value"]}/>)
    })

    return (
      <DeviceOrientation lockOrientation={'portrait'}>
        <Orientation orientation='portrait' alwaysRender={false}>
          <div className="App">
            <Helmet>
                <title>IAMLA | Tours</title>
                <meta name="description" content="IAMLA | Tours"/>
            </Helmet>


            <Header />
            <div className="container home">
              <h1 className="header-title">Permanent Exhibition</h1>
              <div className="winery-list content">{toursView} </div>
              <Footer />
            </div>


             {this.state.hasLoaded  === undefined && (

            <Div100vh  dontresize="true"   className={`loadingscreen ${this.state.data.length ? "fadeOut" : ""}`}>
                   <img src="/images/iamla-identity-black.png" alt="logo IAMLA"/>  
            </Div100vh>
            )}

          </div>
        </Orientation>

        <Orientation orientation='landscape'  alwaysRender={false}>
          <Div100vh  dontresize="true" className="landscapeDiv">
            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
          </Div100vh>
        </Orientation>


      </DeviceOrientation>
      
    );
  }
}

export default Home;
