import React from 'react';

export default class Player extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = { play: false };
	  }

	play = () => {
	    
	    /*if (this.state.play) {
	      this.setState({ play: false });
	      this.audio.pause();
	    } else {
	      this.setState({ play: true });
	      this.audio.play();
	    }*/


	    if (this.audio.paused === false) {
	    	this.audio.pause();
	    	this.setState({ play: false });
	    } else {
	    	this.setState({ play: true });
	      this.audio.play();
	    }
	}

	back = () => {
		this.audio.currentTime -= 5;
	}


	forward = () => {
		this.audio.currentTime += 10;
	}

	mouseDown = (e) => {
		window.addEventListener('touchmove', this.mouseMove);
		window.addEventListener('touchend', this.mouseUp);
	};


	mouseMove = (e) => {
		
		if (typeof e.touches != "undefined") {
		    this.positionHandle(e.touches[0].clientX);
		    this.audio.currentTime = ((e.touches[0].clientX- this.timeline.offsetLeft)  / this.timeline.offsetWidth) * this.audio.duration;
		}else{
			this.positionHandle(e.pageX);
			this.audio.currentTime = ((e.pageX- this.timeline.offsetLeft) / this.timeline.offsetWidth) * this.audio.duration;
		}
		




	}


	positionHandle = (position) => {
	  // Width of the timeline
		var timelineWidth = this.timeline.offsetWidth;

		// Left position of the handle
		var handleLeft = position - this.timeline.offsetLeft;
		

		

		if (handleLeft >= 0 && handleLeft <= timelineWidth) {
			this.handle.style.width = handleLeft + "px";
		}

		if (handleLeft < 0) {
			this.handle.style.width = "1px";
		}
		if (handleLeft > timelineWidth) {
			this.handle.style.width = timelineWidth + "px";
		}
	};



	mouseUp = (e) => {
		window.removeEventListener('touchmove', this.mouseMove);
		window.removeEventListener('touchend', this.mouseUp);
	};

  componentDidMount() {




  	
  	this.audio.addEventListener("ended", () => {
  		this.setState({ play: false });
		this.audio.currentTime = 0;
		this.positionHandle(this.timeline.offsetLeft);

  	});


  	this.audio.addEventListener('canplaythrough', (event) => {

	    var timeleft = Math.floor(this.audio.duration-this.audio.currentTime).toString(); 
	    this.remindingTime.innerHTML = this.formatSecondsAsTime(timeleft);
	});



    this.audio.addEventListener("timeupdate", () => {
    	let ratio = this.audio.currentTime / this.audio.duration;
	    let position = this.timeline.offsetWidth * ratio;
	    this.positionHandle(position+this.timeline.offsetLeft);
	    

	    var currTime = Math.floor(this.audio.currentTime).toString(); 
	    this.timeSpent.innerHTML = this.formatSecondsAsTime(currTime);

	    var timeleft = Math.floor(this.audio.duration-this.audio.currentTime).toString(); 
	    this.remindingTime.innerHTML = this.formatSecondsAsTime(timeleft);
	});

	this.audio.load();

  }


  	formatSecondsAsTime(secs, format) {
		var hr  = Math.floor(secs / 3600);
		var min = Math.floor((secs - (hr * 3600))/60);
		var sec = Math.floor(secs - (hr * 3600) -  (min * 60));

		if (min < 10){ 
			min = "0" + min; 
		}
		if (sec < 10){ 
			sec  = "0" + sec;
		}

		return min + ':' + sec;
	}

	



    render() {
        return  (
        	<>
        	<div id="timeline" ref={(timeline) => { this.timeline = timeline }} onClick={this.mouseMove} >
			    <div id="handle" onTouchStart={this.mouseDown}  ref={(handle) => { this.handle = handle }} />
			</div>
			<div className="audio-controls-time-container"><p ref={(timeSpent) => { this.timeSpent = timeSpent }}>00:00</p><p ref={(remindingTime) => { this.remindingTime = remindingTime }}></p></div>
        	<audio src={this.props.audio} ref={(audio) => { this.audio = audio } }  autoPlay={false}/>
        	<div className="audio-controls-buttons">
        			<div onClick={this.back} className="icon-back" />
        			<div onClick={this.play} className={!this.state.play ? "icon icon-play" : "icon icon-pause"} />
        			<div onClick={this.forward} className="icon-forward" />
        	</div>

            </>
            )
    }
}