import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './index.css';
import Home from './Home';
import TourDetailsView from "./Views/TourDetailsView";
import AudioDetailsView from "./Views/AudioDetailsView";
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';


ReactGA.initialize('UA-93697103-1');
ReactGA.pageview(window.location.pathname + window.location.search);




const routing = (
	<Router>
	  <Switch>
	      <Route exact path="/" component={Home} />
	      <Route exact path="/tour/:id" component={TourDetailsView} />
	      <Route exact path="/audio/:id" component={AudioDetailsView} />
	  </Switch>
  	</Router>
)

ReactDOM.render(routing, document.getElementById('root'))




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
