var base64 = require('base-64');
const TOURS_URL = 'https://iamla.gretel.io/content/tour/en?_format=hal_json';
const AUDIO_STOP_URL = 'https://iamla.gretel.io/node-get/';
const ARG_URL = "/en?_format=hal_json";

let HEADERS = new Headers({
   'Authorization': 'Basic '+base64.encode("restful:restful")
 });



var loadTours = function(callback) {
  

    fetch(TOURS_URL, {
      headers: HEADERS })
      .then(function (response) {
        return response.json();
      })
      .then((data) => callback(data))
      .catch(err => console.log('Fetching Tours Failed', err));
}

exports.loadTours = loadTours;


var loadNode = function(id, callback) {
  

  fetch(AUDIO_STOP_URL+id+ARG_URL, {
    headers: HEADERS })
    .then(function (response) {
      return response.json();
    })
    .then((data) => callback(data))
    .catch(err => console.log('Fetching loadNode Failed', err));
}

exports.loadNode = loadNode;

