import React from 'react';

export default class Header extends React.Component {

  render() {
  	
  	//let currentItem = this.props.currentItem
    //if(currentItem.length === 0) {
      //let from = ((new URL(document.location)).searchParams).get("from");
      
      //currentItem=from
   // }

   let backURL = this.props.backURL
   //console.log(backURL)

    return (
      <div className="menu">
          {backURL !== undefined && (<a href={backURL} className="backButton">BACK</a>)}
        	<a href="/">
        		<img src="/images/iamla-identity.png" alt="logo IAMLA"/>
        	</a>
        	
      </div>
    );
  }
}