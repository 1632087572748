import React from 'react';

export default class AudioStopItem extends React.Component {

	

	render() {

		const audioStop = this.props.audioStop

		console.log(audioStop)
	    let backgroundImage = "/images/placeholder-image.jpg";
	    if(audioStop.field_item_thumbnail.length>0){
	       backgroundImage = audioStop.field_item_thumbnail[0]["value"].replace(/ /g, '%20')

	    }
		
		return (
		  <div className="flight-item">
		      <a href={"/audio/"+this.props.audioId}>
		      	<div className="flight-item-thumb" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
		        <h3 className="blueCopy">{audioStop.title[0]["value"]}</h3>
		        <p className="lightGreyColor" >{audioStop.field_item_subtitle[0]["value"]}</p>
		        
		        <img src="/images/more-arrow.png" className="more-arrow" alt="more arrow"/>
		      </a>
		  </div>
	);
	}

}