import React from 'react';
import {Helmet} from "react-helmet";
import Header from "../Components/Header";
import AudioStopItem from "../Components/AudioStop/AudioStopItem";

import Div100vh from 'react-div-100vh'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'


var loadNode  = require('../utils/api.js').loadNode;

export default class TourDetailsView extends React.Component {
  constructor() {
    super();
    this.state = { audioStops:[], tour: null, };
  }

  componentDidMount() {
    loadNode(this.props.match.params.id, this.updateData.bind(this))
  }


  updateData(responseData) {
    //console.log(responseData)
    

    this.setState({tour: responseData, audioStops: responseData.field_tour_items});
  }




  render() {
    const tour = this.state.tour
    
    let audioStops = this.state.audioStops

    let backgroundImage = "/images/placeholder-image.jpg";
    if(tour != null && tour.field_tour_thumbnail.length>0){
       backgroundImage = tour.field_tour_thumbnail[0]["value"].replace(/ /g, '%20')
    }


    const audioStopViews = audioStops.map((audioStop, index) => {
      //console.log(audioStop["value"].title[0]["value"])
      return (<AudioStopItem key={audioStop["value"].title[0]["value"]} audioStop={audioStop["value"]} audioTitle={audioStop["value"].title[0]["value"]}  audioId={audioStop["value"].nid[0]["value"]} />)
    })


    return (
      <DeviceOrientation lockOrientation={'portrait'}>
        <Orientation orientation='portrait' alwaysRender={false}>
      <div className="App">
        {(tour != null)  &&
          (<Helmet>
              <title>IAMLA | Tour {tour.title[0]["value"]}</title>
              <meta name="description" content={"IAMLA | Tour "+tour.title[0]["value"]}/>
          </Helmet>)
          }

        <Header backURL="/"/>
        
           {(tour != null)  &&
           (
            <div className="container tourdetails">
              <h1 className="header-title">{tour.title[0]["value"]}</h1>
              <div className="image-details" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
              <div className="flight-list-container">
                    {audioStopViews}
              </div>
              
              
            </div>
            )}
      
      </div>

      </Orientation>

        <Orientation orientation='landscape'  alwaysRender={false}>
          <Div100vh  dontresize="true" className="landscapeDiv">
            <p>For the optimal tour experience,<br/> please turn your phone to portrait mode.</p>
          </Div100vh>
        </Orientation>


      </DeviceOrientation>
    );
  }

}